
.morphed-image-container.xLogo{
    position: absolute;
    top: -39%;
    left: 60.3%;
    z-index: 100;
    scale: 1;
}

/* Fixed Images Starts */

.fixed-plus{
    position: absolute; 
    top: 36%;
    left: 11.2%;
}

.fixed-raised-to{
    position: absolute ; 
    top: 62%;
    left: 11.2%;
}

.fixed-equals{
    position: absolute; 
    top: 86.6%;
    left: 11.2%;
}


/* Fixed Images  Ends */


.styles{
    text-align :left;
    color:#0000FE;
}

.styles1 {
    text-align : left;
}

.textp {
    margin-top: 12em;
}

.imgp{
    margin-top: 6em;
}

.def {
    justify-content: space-between;
    gap : 20px;
}

.divStyle {
    position : relative;
    background-color: #F7F6F6;
    width: 100%;
    margin-top:8em;
  }
  

  .secondDIv
  {
    padding-top:6em;
  }
  
   .ptag{
    margin-top:3em;
    font-weight: 400;
    padding-left:10em;
    text-align : left;
    padding-right:5em;
  }
  .stag{
    font-size: 16px;
    padding-right:10em;
    float:left;
    text-align:left;
  }
  .nhtag{
    text-align:left;
    margin-top:2em;
  }

  .zIndex{
    z-index : 100;
  }
  .nhtag1{
    text-align:left;
    margin-top:2em;
  }
  .nhtag2{
    text-align:left;
    margin-top:2em;
  }

  .textp{
      margin-top:5em;
      margin-left: 18em;
  }

   .def{
    justify-content: space-between;
    align-items: center;
  }
  .ml{
    color : #0031FF;
    float:left;
  }
  .ml2{
    color : #0031FF;
    float:left;
  }
  .ml3{
    color : #0031FF;
    float:left;
  }
  .pad
  {
    padding-bottom:7em;
  }

  .container{
    width : 85vw;
    margin : 0 auto;
  } 
  
  .img {
    margin-left : 19px;
}

 .divStyle11 {
    background-color: #252525;
    width: 100%;
    margin-top:8em;
  }
  .secondDIv11
  {
    width: 85vw;
    margin:0 auto;
    padding-top:2em;
    padding-bottom:5em;
  }
   .spantag{
    text-align : left;
    color: #fff;
  }
   .containerStyle {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items : center;
    padding-top : 25px;
    padding-bottom : 25px;
}
 .txt1{
    font-size:41px;
    margin-top:2em;
    text-align:left;
    color:white;
    
}
 .txt2{
    margin-top:7em;
    width:49%;
    float:right;
    padding-right:2em;
    text-align:left;
    color:white;
}
.txt3
{
    font-weight: 600;
    color:white;
}
.txtimg
{
    width:94%;
}
.txtimg2
{
    width:94%;
}
 .txtimg3
{
    width:94%;
}
 .txtimg4
{
    width:94%;
}

.part2
{
    margin-left:auto;
    color:white;
    font-size:20px;
    float:left;
}
.part21
{
    margin-left:auto;
    color:white;
    font-size:20px;
    float:left;
}
.part22
{
    margin-left:auto;
    color:white;
    font-size:20px;
    float:left;
}
.part23
{
    margin-left:auto;
    color:white;
    font-size:20px;
    float:left;
}
 .part3
{
    margin-left:auto;
    margin-right: 19px;
    text-align : right;
    width:235px;
    color:#2FD549;
    font-size:40px;
}
.part33
{
    margin-left:auto;
    margin-right: 0px;
    text-align : right;
    width:235px;
    color:#2FD549;
    font-size:40px;
} 

.imageContainer{
    width: 250px;
    display: flex;
    justify-content: flex-start;
}

.image-part{
    opacity: 0;
    transition: all 0.5s ease-out ;
}

.image-part.active{
    opacity: 1;
    transition: all 0.5s ease-out ;
}

.morphed-image-container.xLogo svg{
    width: 600px;
    transition: width 1s ease-out;
}

.morphed-image-container{
    position: absolute;
    width: 350px;
    top: 30%;
    left: 5%;
    transition: all 1s ease-out;
}

.morphed-image-container.raisedTo{
    top: 55%;
    left: 5%;
    transition: all 1s ease-out;
}

.morphed-image-container.equals{
    top: 80%;
    left: 5%;
    transition: all 1s ease-out ;
}  

        
@keyframes morph-animations-plus-to-raised-1 {
    
    0%, 35% { 
        d: path("M4,67 L148,67.4074 L149,67 L149,68 L150,68 L150,68 L151,69 L151,69 L152,70 L152,70 L152,71 L152,71 L152,96.1481 L152,97 L152,97 L152,98 L151,99 L151,99 L150,99 L150,100 L149,100 L149,100 L148,100 L4,100.148 L3,100 L3,100 L2,100 L2,99 L1,99 L1,99 L0,98 L0,97 L0,97 L0,96 L0,71.4074 L0,71.4074 L0,71 L0,70 L0,70 L1,69 L1,69 L2,68 L2,68 L3,68 L3,67 L4,67"); 
        fill: black;
        
    }
    55%, 100% { 
        d: path("M65.4535,41.744 L71,42 L76,42 L81,42 L86.3815,41.744 L89,48 L91,52 L92,55 L94,59 L95,62 L98,68 L100,72 L101,75 L104,81 L106,85 L107,88 L109.806,94.448 L104,94 L99,94 L94,94 L88.6855,94.448 L87,91 L85,87 L82,80 L79,73 L77,69 L75.9175,65.84 L74,69 L72,73 L69,80 L66,87 L64,91 L62.9575,94.448 L62.9575,94.448 L57,94 L52,94 L47,94 L41.8375,94.448 L45,88 L48,81 L51,75 L54,68 L57,62 L60,55 L63,48 L65.4535,41.744"); 
        fill: #0031FF;   
        
    }
    
    }
    
    
    @keyframes morph-animations-plus-to-raised-2 {
        
    0%, 35% { 
        d: path("M61,78 L65,78 L68,78 L74,78 L78,78 L81,78 L87,78 L88,78 L88,78 L89,78 L89,79 L90,79 L90,80 L91,80 L91,81 L91,81 L91,82 L91,87 L91,91 L91,96 L91,100 L91,105 L91,109 L91,113 L91,117 L91,122 L91,126 L91,131 L91,135 L91,140 L91,144 L91,148 L91,152 L91,153 L91,153 L91,154 L90,154 L90,155 L89,155 L89,156 L88,156 L88,156 L87,156 L81,156 L78,156 L74,156 L68,156 L65,156 L61,156 L61,156 L60,156 L60,156 L59,156 L59,155 L58,155 L58,154 L57,154 L57,153 L57,153 L57,152 L57,148 L57,144 L57,140 L57,135 L57,131 L57,126 L57,122 L57,117 L57,113 L57,109 L57,105 L57,100 L57,96 L57,91 L57,87 L57,82 L57,81 L57,81 L57,80 L58,80 L58,79 L59,79 L59,78 L60,78 L60,78 L61,78"); 
        fill: black;
        
    }
    55%, 100% { 
        d: path("M20,24 L40.6,24.0799561 L40.6,26.095959999999998 L39,28 L37,30 L35,32 L34,35 L32,37 L31,39 L29,42 L28,44 L27,47 L26,50 L25,52 L24,55 L23,58 L22,61 L22,64 L21,66 L21,69 L21,72 L21,75 L21,78 L21,81 L21,84 L21,87 L21,90 L22,93 L22,95 L23,98 L24,101 L25,104 L26,107 L27,109 L28,112 L29,114 L31,117 L32,119 L34,122 L35,124 L37,126 L39,128 L41,130 L40.6,131.98399999999998 L20.056,131.98399999999998 L20.056,131.98399999999998 L18,130 L17,128 L15,126 L13,124 L12,122 L10,119 L9,117 L8,114 L7,112 L5,109 L4,106 L4,103 L3,100 L2,97 L2,94 L1,91 L1,88 L0,85 L0,81 L0,78 L0,75 L0,71 L1,68 L1,65 L2,62 L2,59 L3,56 L4,53 L4,50 L5,47 L7,44 L8,42 L9,39 L10,37 L12,34 L13,32 L15,30 L17,28 L18,26 L20,24"); 
        fill: black;   
        
    }
    
    }
    
    
    @keyframes morph-animations-plus-to-raised-3 {
        
    0%, 35% { 
        d: path("M61,0 L65,0 L68,0 L71,0 L74,0 L78,0 L81,0 L84,0 L87,0 L88,0 L88,0 L89,0 L89,1 L90,1 L90,2 L91,2 L91,3 L91,3 L91,4 L91,8 L91,11 L91,14 L91,17 L91,21 L91,24 L91,27 L91,30 L91,34 L91,37 L91,40 L91,43 L91,46 L91,49 L91,52 L91,55.7037 L91,56 L91,57 L91,58 L90,58 L90,59 L89,59 L89,59 L88,59 L88,60 L87,60 L84,60 L81,60 L78,60 L74,60 L71,60 L68,60 L65,60 L61,59.7037 L61,59.7037 L60,60 L60,59 L59,59 L59,59 L58,59 L58,58 L57,58 L57,57 L57,56 L57,56 L57,50 L57,47 L57,43 L57,37 L57,34 L57,30 L57,24 L57,21 L57,17 L57,11 L57,8 L57,4 L57,3 L57,3 L57,2 L58,2 L58,1 L59,1 L59,0 L60,0 L60,0 L61,0"); 
        fill: #0031FF;
        
    }
    55%, 100% { 
        d: path("M131.58,24.0799561 L133,26 L135,28 L137,30 L138,32 L140,34 L141,37 L143,39 L144,42 L145,44 L146,47 L147,50 L148,53 L149,56 L150,59 L150,62 L151,65 L151,68 L151,71 L151,75 L151,78 L151,81 L151,85 L151,88 L151,91 L150,94 L150,97 L149,100 L148,103 L147,106 L146,109 L145,112 L144,114 L143,117 L141,119 L140,122 L138,124 L137,126 L135,128 L133,130 L132,132 L111.035,131.98399999999998 L111.035,131.98399999999998 L111.035,130.064 L113,128 L115,126 L116,124 L118,122 L119,119 L121,117 L122,114 L123,112 L125,109 L126,107 L127,104 L128,101 L128,98 L129,95 L130,93 L130,90 L131,87 L131,84 L131,81 L131,78 L131,75 L131,72 L131,69 L130,66 L130,64 L129,61 L128,58 L128,55 L127,52 L126,50 L125,47 L123,44 L122,42 L121,39 L119,37 L118,35 L116,32 L115,30 L113,28 L111,26 L111.035,24.0799561 L131.58,24.0799561"); 
        fill: black;   
        
    }
    
    }



    @keyframes raised-to-equals-1 {
                  
        0%, 35% { 
          d: path("M65.4535,41.744 L71,42 L76,42 L81,42 L86.3815,41.744 L89,48 L91,52 L92,55 L94,59 L95,62 L98,68 L100,72 L101,75 L104,81 L106,85 L107,88 L109.806,94.448 L104,94 L99,94 L94,94 L88.6855,94.448 L87,91 L85,87 L82,80 L79,73 L77,69 L75.9175,65.84 L74,69 L72,73 L69,80 L66,87 L64,91 L62.9575,94.448 L62.9575,94.448 L57,94 L52,94 L47,94 L41.8375,94.448 L45,88 L48,81 L51,75 L54,68 L57,62 L60,55 L63,48 L65.4535,41.744"); 
          fill: #0031FF;
           
        }
        55%, 100% { 
          d: path("M7,14 L145,14 L146,14 L146,14 L147,14 L147,15 L148,15 L148,16 L149,16 L149,17 L149,17 L149,18 L149,42 L149,43 L149,43 L149,44 L148,44 L148,45 L147,45 L147,46 L146,46 L146,46 L145,46 L7,46 L6,46 L6,46 L5,46 L5,45 L4,45 L4,44 L3,44 L3,43 L3,43 L3,42 L3,18 L3,18 L3,17 L3,17 L3,16 L4,16 L4,15 L5,15 L5,14 L6,14 L6,14 L7,14"); 
          fill: #0031FF;   
            
        }
        
        }

    @keyframes raised-to-equals-2 {
        
        0%, 35% { 
          d: path("M20,24 L40.6,24.0799561 L40.6,26.095959999999998 L39,28 L37,30 L35,32 L34,35 L32,37 L31,39 L29,42 L28,44 L27,47 L26,50 L25,52 L24,55 L23,58 L22,61 L22,64 L21,66 L21,69 L21,72 L21,75 L21,78 L21,81 L21,84 L21,87 L21,90 L22,93 L22,95 L23,98 L24,101 L25,104 L26,107 L27,109 L28,112 L29,114 L31,117 L32,119 L34,122 L35,124 L37,126 L39,128 L41,130 L40.6,131.98399999999998 L20.056,131.98399999999998 L20.056,131.98399999999998 L18,130 L17,128 L15,126 L13,124 L12,122 L10,119 L9,117 L8,114 L7,112 L5,109 L4,106 L4,103 L3,100 L2,97 L2,94 L1,91 L1,88 L0,85 L0,81 L0,78 L0,75 L0,71 L1,68 L1,65 L2,62 L2,59 L3,56 L4,53 L4,50 L5,47 L7,44 L8,42 L9,39 L10,37 L12,34 L13,32 L15,30 L17,28 L18,26 L20,24"); 
          fill: black;
           
        }
        55%, 100% { 
          d: path("M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63"); 
          fill: black;   
            
        }
        
        }
    
    
        @keyframes fadeIn0 {
            0%, 50% { opacity: 1; }
            75%, 100% { opacity: 0; }
        }

        #final-path0 {
            animation: fadeIn0 1s infinite;
            animation-timing-function: ease-out;
        }
            
        @keyframes x-to-plus-1 {
    
            0%, 35% { 
              d: path("M4,67 L148,67.4074 L149,67 L149,68 L150,68 L150,68 L151,69 L151,69 L152,70 L152,70 L152,71 L152,71 L152,96.1481 L152,97 L152,97 L152,98 L151,99 L151,99 L150,99 L150,100 L149,100 L149,100 L148,100 L4,100.148 L3,100 L3,100 L2,100 L2,99 L1,99 L1,99 L0,98 L0,97 L0,97 L0,96 L0,71.4074 L0,71.4074 L0,71 L0,70 L0,70 L1,69 L1,69 L2,68 L2,68 L3,68 L3,67 L4,67"); 
              fill: #1209E7;
               
            }
            55%, 100% { 
              d: path("M158.624 0.293091C149.425 0.436219 141.209 0.644296 140.368 0.75535C138.548 0.995902 136.852 1.79573 135.792 2.91329C134.26 4.5296 91.738 60.2551 91.2903 61.2335C90.2458 63.5168 90.2089 65.8479 91.1868 67.7834C91.9739 69.3409 111.328 94.1937 112.259 94.8424C113.455 95.6753 114.676 96.0493 116.212 96.0531C118.159 96.058 119.616 95.46 120.915 94.1229C122.643 92.3442 183.805 12.8008 184.367 11.6008C185.054 10.136 185.202 7.76622 184.712 6.07233C183.632 2.33156 180.286 -0.107226 176.386 0.00362773C175.816 0.0198649 167.823 0.150164 158.624 0.293091Z"); 
              fill: #0031FF;   
                
            }
            
            }
            
            
            @keyframes x-to-plus-2 {
                
            0%, 35% { 
              d: path("M61,78 L65,78 L68,78 L74,78 L78,78 L81,78 L87,78 L88,78 L88,78 L89,78 L89,79 L90,79 L90,80 L91,80 L91,81 L91,81 L91,82 L91,87 L91,91 L91,96 L91,100 L91,105 L91,109 L91,113 L91,117 L91,122 L91,126 L91,131 L91,135 L91,140 L91,144 L91,148 L91,152 L91,153 L91,153 L91,154 L90,154 L90,155 L89,155 L89,156 L88,156 L88,156 L87,156 L81,156 L78,156 L74,156 L68,156 L65,156 L61,156 L61,156 L60,156 L60,156 L59,156 L59,155 L58,155 L58,154 L57,154 L57,153 L57,153 L57,152 L57,148 L57,144 L57,140 L57,135 L57,131 L57,126 L57,122 L57,117 L57,113 L57,109 L57,105 L57,100 L57,96 L57,91 L57,87 L57,82 L57,81 L57,81 L57,80 L58,80 L58,79 L59,79 L59,78 L60,78 L60,78 L61,78"); 
              fill: black;
               
            }
            55%, 100% { 
              d: path("M26.4895 33.8704C5.00706 34.1218 6.76617 34.0456 5.18672 34.7932C1.72224 36.4325 -0.0067974 39.8277 2.00815e-05 44.9773C0.00342882 47.5752 0.294374 49.0839 1.12089 50.7908C1.38798 51.3421 12.258 65.3468 25.2766 81.9123C38.2954 98.4779 48.9471 112.076 48.9471 112.131C48.9471 112.186 38.361 126.416 25.4224 143.753C12.484 161.091 1.65386 175.637 1.3555 176.078C0.0274925 178.041 -0.370532 181.481 0.430121 184.073C1.29734 186.88 3.72016 189.387 6.3501 190.199C7.61194 190.589 8.36648 190.61 25.8485 190.737C42.2457 190.856 44.1579 190.837 45.2876 190.544C46.9747 190.106 48.0109 189.55 49.1849 188.452C49.7255 187.947 56.6302 179.154 64.5287 168.913L78.8895 150.293L79.3294 150.757C79.5714 151.012 86.3617 159.618 94.4187 169.882C102.476 180.145 109.359 188.788 109.715 189.087C110.652 189.875 112.119 190.571 113.559 190.91C115.474 191.362 149.366 191.357 151.102 190.905C152.766 190.472 154.116 189.689 155.464 188.375C158.134 185.772 158.887 181.838 157.355 178.492C156.851 177.392 49.6762 37.9686 47.9929 36.224C46.334 34.5047 43.7668 33.6086 40.7261 33.688C40.1195 33.7039 33.7131 33.786 26.4895 33.8704Z"); 
              fill: black;   
                
            }
            
            }
            
            
            @keyframes x-to-plus-3 {
             0%, 50% { opacity: 1; }
            75%, 100% { opacity: 0; }
            }


            @media (max-width : 1920px) {
                .morphed-image-container.xLogo{
                    top: -40%;
                    left: 65.3%;
                }
            }

            @media (max-width : 1680px) {
                .morphed-image-container.xLogo{
                    top: -39%;
                    left: 62.3%;
                }
            }


            @media (max-width : 1440px) {
                .morphed-image-container.xLogo{
                    top: -37%;
                    left: 57.3%;
                }
            }
            
            @media (max-width : 1350px) {
                .morphed-image-container.xLogo{
                    top: -37%;
                    left: 54.3%;
                }
            }
            
            @media (max-width : 1277px) {
            
                .morphed-image-container.xLogo{
                    top: -35%;
                    left: 53.3%;
                }
            
            
            }
            
            @media (max-width : 1200px) {
                .morphed-image-container.xLogo svg{
                    width: 532px;
                }
            }

            @media (max-width : 1024px) {
                
                .morphed-image-container.xLogo.inLogo svg{
                    width: 585.8px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -29.9%;
                    left: 41.7%;
                }

                .morphed-image-container.xLogo svg{
                    width: 585.8px;
                    transition: width 1s ease-out;
                }

                

                .img {
                    margin-left : 0px;
                }

                .imageContainer{
                    width: 200px;
                    display: flex;
                }
                

            }

            @media (max-width : 990px) {
                .morphed-image-container.xLogo.inLogo{
                    top: -27.9%;
                    left: 55.7%;
                    transition: all 1s ease-out;
                    z-index: 10000;
                    scale: 1;
                }

                .morphed-image-container.xLogo.inLogo svg{
                    width: 420px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -27.9%;
                    left: 55.7%;
                    transition: all 1s ease-out;
                    z-index: 10000;
                    scale: 1;
                }

                .morphed-image-container.xLogo svg{
                    width: 420px;
                    transition: width 1s ease-out;
                }

                .mimg{
                    height: 250px;
                    width: 250px;
                }
                .mimg2{
                    height: 250px;
                    width: 250px;
                }

                .containerStyle.containerMobileStyle{
                    flex-direction: column;
                  }

                  .txt2{
                    width: 100%;
                    margin-top: 1em;
                  }
                  

                .ml3{
                    text-align: left;
                }

                  .txt1{
                    width: 100%;
                    margin-top: 0.5em;
                  }

                .img {
                    margin-left : 0px;
                }

                .imageContainer{
                    width: 200px;
                    display: flex;
                }
                

            }

            @media (max-width : 850px) {
                .morphed-image-container.xLogo.inLogo{
                    top: -22.9%;
                    left: 50%;
                    translate: -50%;
                    transition: all 1s ease-out;
                    z-index: 10000;
                    scale: 1;
                }

                .morphed-image-container.xLogo.inLogo svg{
                    width: 330px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -22.9%;
                    left: 50%;
                    translate: -50%;
                    transition: all 1s ease-out;
                    z-index: 10000;
                    scale: 1;
                }

                .morphed-image-container.xLogo svg{
                    width: 330px;
                    transition: width 1s ease-out;
                }

                .fixed-plus{
                    position: absolute ; 
                    top: 26%;
                    left: 50%;
                }


                .nhtag1{
                    text-align: left;
                    margin-top: 5em;
                }

                .def {
                    flex-direction: column;
                    align-items: center;
                }

                .textp{
                    margin-top: 0;
                }

                .textp{
                    margin-left: 0;
                }

                .styles1{
                    text-align: center;
                }

                .styles{
                    text-align: center;
                }

                .imgp {
                    margin-top: 0;
                }

                .mimg{
                    height: 250px;
                    width: 250px;
                }
                .mimg2{
                    margin-top: 10px;

                    height: 230px;
                    width: 230px;
                    left: 50%;
                    translate: -50%;
                }



                .morphed-image-container{
                    top: 17%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.raisedTo{
                    top: 41%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.equals{
                    top: 66%;
                    left: 50%;
                    translate: -50%;
                }

                .fixed-plus{
                    top: 22%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-raised-to{
                    top: 47%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-equals{
                    top: 72%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.equals{
                    transition: top 1s ease-out, left 1s ease-out ;
                }

                .nhtag {
                    text-align: left;
                    margin-top: 250px;
                }

                .nhtag1 {
                    text-align: left;
                    margin-top: 250px;
                }

                .nhtag2 {
                    text-align: left;
                    margin-top: 250px;
                }

                .ptag{
                    margin-top:3em;
                    font-weight: 400;
                    padding-left: 0;
                    text-align : left;
                    padding-right:0;
                  }

                  .stag{
                    padding-right: 0;
                  }


                .imageContainer{
                    width: 180px;
                    display: flex;
                }
                  
            }

            @media (max-width : 768px) {
                .morphed-image-container.xLogo.inLogo{
                    top: -22.9%;
                    left: 50%;
                    translate: -50%;
                    transition: all 1s ease-out;
                    z-index: 10000;
                    scale: 1;
                }

                .morphed-image-container.xLogo.inLogo svg{
                    width: 330px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -22.9%;
                    left: 50%;
                    translate: -50%;
                    transition: all 1s ease-out;
                    z-index: 10000;
                    scale: 1;
                }

                .morphed-image-container.xLogo svg{
                    width: 330px;
                    transition: width 1s ease-out;
                }

                .fixed-plus{
                    position: absolute ; 
                    top: 26%;
                    left: 50%;
                }


                .nhtag1{
                    text-align: left;
                    margin-top: 5em;
                }

                .def {
                    flex-direction: column;
                    align-items: center;
                    gap: 100px;
                }

                .textp{
                    margin-top: 0;
                }

                .textp{
                    margin-left: 0;
                }

                .styles1{
                    text-align: center;
                }

                .styles{
                    text-align: center;
                }

                .imgp {
                    margin-top: 0;
                }

                .mimg{
                    height: 250px;
                    width: 250px;
                }
                .mimg2{
                    margin-top: 10px;

                    height: 230px;
                    width: 230px;
                    left: 50%;
                    translate: -50%;
                }



                .morphed-image-container{
                    top: 17%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.raisedTo{
                    top: 41%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.equals{
                    top: 66%;
                    left: 50%;
                    translate: -50%;
                }

                .fixed-plus{
                    top: 22%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-raised-to{
                    top: 47%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-equals{
                    top: 72%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.equals{
                    transition: top 1s ease-out, left 1s ease-out ;
                }

                .nhtag {
                    text-align: left;
                    margin-top: 250px;
                }

                .nhtag1 {
                    text-align: left;
                    margin-top: 250px;
                }

                .nhtag2 {
                    text-align: left;
                    margin-top: 250px;
                }

                .ptag{
                    margin-top:3em;
                    font-weight: 400;
                    padding-left: 0;
                    text-align : left;
                    padding-right:0;
                  }

                  .stag{
                    padding-right: 0;
                  }


                .imageContainer{
                    width: 180px;
                    display: flex;
                }
                  
            }

            @media (max-width : 600px) {

                .containerStyle{
                    width: 94%;
                }

                .morphed-image-container.xLogo.inLogo{
                    top: -25.9%;
                    
                }

                .morphed-image-container.xLogo.inLogo svg{
                    width: 330px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -21.9%;
                }

                .morphed-image-container.xLogo svg{
                    width: 330px;
                    transition: width 1s ease-out;
                }

                .def {
                    flex-direction: column;
                }

                .part3{
                    margin-right: 0;
                }

                .txtimg
                    {
                        width:100%;
                    }
                    .txtimg2
                    {
                        width:100%;
                    }
                    .txtimg3
                    {
                        width:100%;
                    }
                    .txtimg4
                    {
                        width:100%;
                    }

                .textp{
                    margin-top: 0;
                }

                .imgp {
                    margin-top: 0;
                }

                .mimg{
                    height: 200px;
                    width: 200px;
                }
                .mimg2{
                    margin-top: 10px;
                    height: 190px;
                    width: 190px;
                }


                .ptag{
                    margin-top:3em;
                    font-weight: 400;
                    padding-left: 0;
                    text-align : left;
                    padding-right:0;
                  }

                  .stag{
                    padding-right: 0;
                  }

                  .img {
                    width: 100px;
                }

                .img-2{
                    width: 80px;
                }

                .imageContainer{
                    width: 130px;
                }


                .morphed-image-container{
                    top: 17%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.raisedTo{
                    top: 41%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.equals{
                    top: 66%;
                    left: 50%;
                    translate: -50%;
                }

                .fixed-plus{
                    top: 22%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-raised-to{
                    top: 47%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-equals{
                    top: 72%;
                    left: 50%;
                    translate: -50%;
                }

            }

            @media (max-width : 600px) {

                .containerStyle{
                    width: 94%;
                }

                .morphed-image-container.xLogo.inLogo{
                    top: -25.9%;
                }

                .morphed-image-container.xLogo.inLogo svg{
                    width: 330px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -18.7%;
                }


                .morphed-image-container{
                    top: 17%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.raisedTo{
                    top: 41%;
                    left: 50%;
                    translate: -50%;
                }

                .morphed-image-container.equals{
                    top: 67%;
                    left: 50%;
                    translate: -50%;
                }

                .fixed-plus{
                    top: 22%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-raised-to{
                    top: 47%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-equals{
                    top: 72%;
                    left: 50%;
                    translate: -50%;
                }

            }

            @media only screen and (min-width: 320px) and (max-width: 370px) {

                .morphed-image-container.xLogo.inLogo{
                    top: -13.9%;
                }

                .morphed-image-container.xLogo.inLogo svg{
                    width: 250px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -13.9%;
                    
                }

                .morphed-image-container.xLogo svg{
                    width: 250px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container{
                    width: 250px;
                }

                .morphed-image-container{
                    top: 20%;
                }

                .morphed-image-container.raisedTo{
                    top: 45.5%;
                }

                .morphed-image-container.equals{
                    top: 70%;
                    left: 50%;
                    translate: -50%;
                }

                .fixed-plus{
                    width: 107.7px;
                    top: 22.6%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-raised-to{
                    width : 106.9px;
                    top: 48.9%;
                }
                
                .fixed-equals{
                    width : 100.5px;
                    top: 73.1%;
                    left: 50%;
                    translate: -50%;
                }

                .def {
                    flex-direction: column;
                }

                .textp{
                    margin-top: 0;
                }

                .imgp {
                    margin-top: 0;
                }

                .mimg{
                    height: 200px;
                    width: 200px;
                }
                .mimg2{
                    height: 200px;
                    width: 200px;
                }


                .ptag{
                    margin-top:3em;
                    font-weight: 400;
                    padding-left: 0;
                    text-align : left;
                    padding-right:0;
                  }

                  .stag{
                    padding-right: 0;
                  }

                  .imageContainer{
                    width: 90px;
                  }

                  .img{
                    width: 80px;
                  }

                  .img-2{
                    width: 70px;
                  }

                  .part3{
                    width: 30%;
                  }

            }

            @media (max-width : 321px) {

                .morphed-image-container.xLogo.inLogo{
                    top: -13.9%;
                }

                .morphed-image-container.xLogo.inLogo svg{
                    width: 250px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container.xLogo{
                    top: -13.9%;
                    
                }

                .morphed-image-container.xLogo svg{
                    width: 250px;
                    transition: width 1s ease-out;
                }

                .morphed-image-container{
                    width: 250px;
                }

                .morphed-image-container{
                    top: 20%;
                }

                .morphed-image-container.raisedTo{
                    top: 45.5%;
                }

                .morphed-image-container.equals{
                    top: 70%;
                    left: 50%;
                    translate: -50%;
                }

                .fixed-plus{
                    width: 107.7px;
                    top: 22.6%;
                    left: 50%;
                    translate: -50%;
                }
                
                .fixed-raised-to{
                    width : 106.9px;
                    top: 48.9%;
                }
                
                .fixed-equals{
                    width : 100.5px;
                    top: 73.1%;
                    left: 50%;
                    translate: -50%;
                }

                .def {
                    flex-direction: column;
                }

                .textp{
                    margin-top: 0;
                }

                .imgp {
                    margin-top: 0;
                }

                .mimg{
                    height: 200px;
                    width: 200px;
                }
                .mimg2{
                    height: 200px;
                    width: 200px;
                }


                .ptag{
                    margin-top:3em;
                    font-weight: 400;
                    padding-left: 0;
                    text-align : left;
                    padding-right:0;
                  }

                  .stag{
                    padding-right: 0;
                  }

                  .imageContainer{
                    width: 90px;
                  }

                  .img{
                    width: 80px;
                  }

                  .img-2{
                    width: 70px;
                  }

                  .part3{
                    width: 30%;
                  }

            }



            