.container-menu {
    width: 85vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0 0 0;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    
}

.container-menu.transparent{
    background-color: transparent;
}

span a {
    font-size: 24px;
    font-weight: 600;
    border: 3px solid #2FD549;
    background-color: transparent;
    padding: 5px 25px;
    color: #000;
    text-decoration: none;
    position: relative;
    z-index: 1;
}

header.transparent span a {   
    color: #fff;
}


span a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #2FD549;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    z-index: -2;
    transition: all 0.3s linear;
}

span a:hover:after {
    width: 100%;
    border-radius: 0rem;
}

span a:hover {
    color: #fff;
}

header {
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: #fff;
    z-index: 10000;
    padding-top: 22px;
    padding-bottom: 22px;
    box-shadow: 0 1px 6px 0 #20212447;
}

header.transparent{
    background-color: transparent;
    box-shadow: none;
}

.full-width-logo {
    position: fixed;
    transition: all 0.5s ease;
}

.full-width-logo {
    position: absolute;
    top: -40%;
    left: -7.6vw;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #003188;
    /* background-color: #000; */
    z-index: 1000;
    transition: all 0.5s ease-out;
}

.full-width-logo img {
    width: 1044px;
    opacity: 1;
    transition: all 0.5s ease-out;
}

.full-width-logo img.active {
    opacity: 1;
    transition: all 0.5s ease-out;
}

.full-width-logo.active {
    top: 0px;
    left: 0;
    width: 239px;
    height: 53px;
    background-color: transparent;
    transition: all 0.5s ease-out;
}

.full-width-logo.active img {
    width: 239px;
    height: 53px;
    transition: all 0.5s ease-out;
}

header .logo {
    width: 239px;
    height: 53px;
    object-fit: contain;
}

header .logo img {
    opacity: 0;
    transition: all 0.5s ease-out;
}

header .logo img.active {
    opacity: 1;
    transition: all 0.5s ease-out;
}

header .logo img {
    width: 239px;
    height: 53px;
}

header nav ul {
    display: flex;
    gap: 40.43px;
    list-style: none;
}

header nav ul li {
    font-size: 24px;
    font-weight: 600;
    position: relative;
}

header nav ul li a {
    text-decoration: none;
    color: #000;
}

header.transparent nav ul li a {
    color: #fff;
    justify-content: flex-end;
}

header nav ul li a {
    justify-content: flex-end;
}

header button {
    font-size: 20px;
    font-weight: 500;
    padding: 5px 23px;
    background-color: #2FD549;
    color: #fff;
    border-radius: 30px;
    border: none;
    cursor: pointer;
}

header nav ul li:hover .submenu {
    display: flex;
}

header nav .submenu {
    display: flex;
    left: 50%;
    display: none;
    transform: translate(-50%);
    flex-direction: column;
    position: absolute;
    gap: 0;
    box-shadow: 0 1px 6px 0 #20212447;
    background-color: #fff;
    transition: all 0.3s ease;

}

header nav .mob-submenu {
    display: flex;
    left: 50%;
    display: none;
    transform: translate(-50%);
    flex-direction: column;
    position: absolute;
    gap: 0;
    box-shadow: 0 1px 6px 0 #20212447;
    background-color: #fff;
    transition: all 0.3s ease;
    display: none;
}

header nav .submenu li {
    width: 100%;
}

header nav .submenu li a {
    display: block;
    padding: 20px 10px;
    color: #000 !important;

}

header nav .submenu li a:hover {
    background-color: #ebebeb;
}

header nav .mob-submenu li {
    width: 100%;
}

header nav .mob-submenu li a {
    display: block;
    padding: 20px 10px;
}

header nav .mob-submenu li a:hover {
    background-color: #ebebeb;
}

.open-menu {
    display: none;
}
.close-menu {
    display: none;
}


.down-arrow-submenu-icon{
    display: none;
}


@media (max-width : 1200px) {

    span a{
        font-size: 34px;
        padding: 0 20px;
    }

    header nav ul {
        gap: 23.43px;
    }

}

@media (max-width : 1160px) {

    span a{
        font-size: 28px;
        padding: 0 20px;
    }

    header nav ul {
        gap: 23.43px;
    }

    header nav ul li {
        font-size: 22px;
        font-weight: 600;
        position: relative;
    }

}

@media (max-width : 1050px) {

    span a{
        font-size: 28px;
        padding: 0 16px;
    }

    header nav ul {
        gap: 20.43px;
    }

    header nav ul li {
        font-size: 20px;
        font-weight: 600;
        position: relative;
    }
    
    .full-width-logo.active {
        width: 200px;
    }
    
    .full-width-logo.active img {
        width: 200px;
    }
    
    header .logo {
        width: 200px;
    }
    
    header .logo img {
        width: 200px;
    }

}


.sizeimgcross{
    width: 30px;
    margin-left: 80px;
    margin-top: 30px;
}


@media (max-width: 990px) {

    span a{
        display: none;
    }    


    header .logo img{
        opacity: 1;
    }

    header.transparent nav ul li a{
        color: #000;
    }

    header.transparent .open-menu img{
        filter: invert();

    }

    .logo.active{
        opacity: 1;
    }


    .full-width-logo {
        left: 50%;
        translate: -50%;
        transition: left 0.5s ease-out;
        opacity: 0;
    }

    

    .full-width-logo.active {
        left: 50%;
        translate: -50%;
        width: 200px;
    }

    .full-width-logo img.active {
        opacity: 1;
    }


    header {
        padding: 0;
        justify-content: space-between;
    }

    header nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        /* width: 100vw; */
        height: 100vh;
        background-color: #fff;
        transform: translateX(-100%);
        transition: transform ease 1s;
        z-index: 10000;
        margin-left: 0;
    }

    header nav.active {
        transform: translateX(0);
    }

    header nav ul {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        gap: 0;
        text-align: right;
    }

    .sizeimgcross {
        margin-right: 10vw;
    }

    header nav ul li a{
        display: block;
        padding: 20px;
    }

    header nav .submenu {
        display: none;
    }

    header nav ul li:hover .submenu {
        display: none;
    }

    header nav .mob-submenu {
        right: 10%;
        padding: 0;
        transform: translate(-10%);
        position: relative;
        box-shadow: none;
        display: flex;
        overflow: hidden;
    }

    header nav .mob-submenu li {
        margin-right: 10%;
        width: 50%;
    }

    header nav .mob-submenu.open {
        /* max-height: 200px; */
        display: block;

    }

    header nav .mob-submenu.close{
        /* max-height: 0; */
        display: none;
    }


    .open-menu {
        display: block;
        width: auto;
        margin-left: auto;
    }

    .open-menu img {
        height: auto;
        width: 35px;
        transform: rotate(180deg);

    }

    .close-menu {
        display: block;
        text-align: right;
    }

    .down-arrow-submenu-icon{
        display: inline;
        height: 12px;

    }
    
    .container-menu{
        position: static;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

@media (max-width: 770px) {


    header button {
        font-size: 16px;
    }

    header .logo {
        object-fit: contain;
    }

    
}

@media (max-width: 600px) {
    

    span a{
        font-size: 22px;
        padding: 0 10px;
    }

    .container-menu {
        width: 85vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        background-color: #fff;
        margin: 0 auto;
    }

}

@media (max-width: 425px) {
    
    .container-menu {
        width: 90vw;
    }
}

.submenu.open {
    display: flex !important;
}
