*{
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Montserrat", sans-serif;
}

body {
  width: 100vw;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}
