.container{
    width: 85vw;
    margin: auto;
}

.contact-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 55px;
}

.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgcontainer{
    width: 364.45px;
    height: 426px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
}

.imgcontainer img.on{
    position: absolute;
    top: 9px;
    left: 8px;
    width: 247.5px;
    aspect-ratio: 0.695;
}

.imgcontainer img.off{
    margin-left: 2px;
    width: 260px;
    aspect-ratio: 0.695;
    cursor: pointer;
}

.header img{
    cursor: pointer;
}

.bulb-conatiner{
    position: absolute;
    cursor: pointer;

}

.bulb-ray{
    position: absolute;
    left: -50px;
    top: -50px;
}


/* Contact Form 2 Starts */

.contact-form{
    width: 60%;
    background-color: #252525;
    padding-top: 29px;
    padding-bottom: 63px;
}

.contact-form h2{
    color: #fff;
    text-align: center;
    font-weight: 700;
    margin-bottom: 47px;
}

.contact-form form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
    
}

.contact-form form .form-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 39px;
}

.contact-form form .form-section input{
    width: 48%;
    background-color: transparent;
    border: 1px solid #C0C0C0;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    color: #fff;
    padding: 18px;
}

.contact-form form .form-section textarea{
    width: 100%;
    background-color: transparent;
    border: 1px solid #C0C0C0;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    color: #fff;
    padding: 18px;
    height: 175px;
    resize: none;
}

.contact-form form .form-section:nth-child(5){
    margin-bottom: 37px;
}

.contact-form form .form-section:nth-child(5) textarea{
    height: 211px;
}

.contact-form form button{
    background-color: transparent;
    border: 4px solid #2FD549;
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    padding: 17px 0;
    color: #fff;
    cursor: pointer;
}

.contact-form form button.active{
    background-color: #2FD549;
    border: 4px solid #2FD549;
}





/* Contact Form 2 Ends */


/* Contact Info Starts */


.contact-info{
    margin-top: 63px;
    padding-top: 62px;
    padding-bottom: 121px;
    background-color: #F7F6F6;
}

.contact-info{
    margin-top: 63px;
    padding-top: 62px;
    padding-bottom: 121px;
    background-color: #F7F6F6;
}

.contact-info .add{
    width: 100%;
}

.contact-info h3{
    width: 90%;
    margin: 0 auto;
    font-weight: 600;
}


.contact-info .info-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
}

.contact-info .info{
    width: 317px;
    display: flex;
    align-items: flex-end;
    gap: 17px;
}

.contact-info .info p{
    font-weight: 600;
    margin-bottom: 13px;
}

.contact-info a{
    text-decoration: none;
    color: #000;
    font-weight: 400;
    font-size: 22px;
    line-height: 26.82px;
}

.Email-container {
    width: 90%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* flex-direction: column; */
    gap: 100px;
}

.Email-container .email{
    display: flex;
    align-items: center;
    gap: 10px;
}

.Email-container .email img{
    width: 41px;
}

.st1
{
    margin-left:-4px;
}

/* Contact Info Ends */


.mobile-view{
    display: none;
}

@media (max-width : 990px) {

    .contact-form{
        margin-top: 50px;
    }

    .header-container{
        display: flex;
        flex-direction: column;
    }

    .contact-form form{
        width: 100%;
    }

    .contact-info .info-container{
        width: 90%;
    }

    .contact-info h3{
        width: 100%;
        margin: 0;
    }

    .contact-info .info{
        width: 317px;
        flex-wrap: wrap;
        /* text-align: center; */
        gap: 17px;
    }
    
    .st1
        {
            margin-left:0;
            margin-top: 0;
        }

        .contact-form{
            width: 100%;
            background-color: #252525;
            padding-top: 29px;
            padding-bottom: 63px;
        }


        .mobile-view{
            display: block;
        }

        .desktop-view{
            display: none;
        }

}

@media (max-width : 768px) {

    .contact-form form .form-section{
        flex-wrap: wrap;
        margin-bottom: 15px;
        gap: 15px;
    }

    .contact-form form .form-section input{
        width: 100%;
    }

}

@media (max-width : 600px) {

    .contact-info .info-container{
        width: 90%;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        margin-top: 25px;
        margin-left: 0;
    }

    .contact-info .info{
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 30px;
    }

    .contact-info h3{
        font-size: 18px;
    }

    .Email-container {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 30px;
    }

    .Email-container .email{
        width: 100%;
        gap: 30px;
    }

    .contact-info .info img{
        width: 50px;
    }
    

    .contact-info h3{
        width: 100%;
        margin: 0;
        text-align: center;
    }

    .contact-info .info h3{
        width: 30%;
        text-align: left;
        margin: 0;
    }

    .contact-header{
        gap: 35px;
    }

    .imgcontainer{
        width: 100%;
        height: 146px;
    }

    .imgcontainer img.on{
        position: absolute;
        width: 100.4px;
        top: 3px;
        left: 4px;
    }

    .bulb-ray{
        position: absolute;
        height: 49px; 
        top: -22px;
        left: -14px;
    }

    .imgcontainer img.off{
        width: 105px;
        height: 151px;
    }

}

@media (max-width : 425px) {
    .container{
        width: 90vw !important;
    }
}
