:root{
    --c: #0031FF; /* the color */
    --b: 1px;    /* border length*/
    --d: 20px;    /* the cube depth */
    
    --_s: calc(var(--d) + var(--b));
}

.col-sm-6{
    width: 50%;
}

.row .text-part{
    width: 100%;
}

.bst{
    width: 100%;
    height: 255px;   
}

.bst img{
    width: 391px;
    height: 47px;
}

.rur{
    width: 100%;
    height: 199px;
}

.rur img{
    width: 161px;
    height: 46px;
}

.earth{
    width: 100%;
    height: 199px;
}

.earth img{
    width: 140px;
    height: 73px;
}

.uscholar{
    width: 100%;
    height: 211px;
}

.uscholar img{
    width: 225px;
    height: 64px;
}

.bjs{
    width: 100%;
    height: 269px;
}

.bjs img{
    width: 130px;
    height: 72px;
}

.micron{
    width: 100%;
    height: 269px;
}

.micron img{
    width: 141px;
    height: 104px;
}

.box-shadow-3d{
    background-color: #fff;
    box-shadow: 0 0 0 0.08px black;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.box-shadow-3d:hover{
    box-shadow: none;
}

.box-shadow-3d img{
    filter: grayscale();
    transition: 0.5s;
}

.box-shadow-3d:hover img{
    filter: none;
}

.d-2 {
    color: #0031FF;
    border: solid #0031FF;
    border-width: 0;
    transform: translate(0, 0);
    outline: none;
    transition: 0.5s;
  }
  
.d-2:hover {
    border: solid #000;
    border-width:var(--b) var(--_s) var(--_s) var(--b);;
    border-color:#0000;
    background:
        conic-gradient(from -90deg at right var(--d)  bottom var(--d),
        #0000 90deg, rgb(255 255 255 /0) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
        conic-gradient(at right var(--_s) bottom var(--_s),
        var(--c) 270deg,#889FFF 0) 100% 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;

    transform: translate(-20px,-20px);

    clip-path: 
        polygon(
        0% 0%,
        calc(100% - var(--d)) 0%,
        100% var(--d),
        100% 100%,
        var(--d) 100%,
        0 calc(100% - var(--d))
        );
}

.containerStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;
}

.bgcolor {
    background-color: #F7F6F6;
    width: 100%;
}

.st1{
    margin-top: 5em;
}
.st2{
    /* font-size: 60px; */
    text-align: left;
}
.st3 {
    /* font-size: 60px; */
    text-align: left;
    color: #7F7F7F;
}
.mtop {
    margin-top: 163px;
}

.no-padding{
    padding: 0;
}


.book-now-btn{
    width: fit-content;
    font-weight: 600;
    border: 5px solid #2FD549;
    background-color: transparent;
    padding: 0px 25px;
    color: #000;
    text-decoration: none;
    position: relative;
    z-index: 1;
    display: block;
    margin-top: 30px;
    line-height: normal;
}

.book-now-btn::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #2FD549;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    z-index: -2;
    transition: all 0.3s linear;
}

.book-now-btn:hover:after{    
    width: 100%;
    border-radius: 0rem;
}

.book-now-btn:hover{    
    color: #fff;
}


@media (max-width : 990px) {

    .row.row-mobile{
        flex-direction: column;
        gap: 30px;
    }

    .mtop{
        margin-top: 0;
    }
    .col-sm-6{
        width: 100%;
    }
    
}

@media (max-width : 600px) {

    .containerStyle{
        width: 100%;
    }

    .row.row-mobile{
        flex-direction: column;
    }
    

    .bst{
        width: 100%;
        object-fit: contain;
        height: 255px;   
    }

    .bst img{
        width: 50%;
        height: auto;
    }
    
    .rur{
        width: 100%;
        object-fit: contain;
        height: 199px;
    }

    .rur img{
        width: 50%;
        height: auto;
    }
    
    .earth{
        width: 100%;
        object-fit: contain;
        height: 199px;
    }

    .earth img{
        width: 50%;
        height: auto;
    }
    
    .uscholar{
        width: 100%;
        object-fit: contain;
        height: 211px;
    }

    .uscholar img{
        width: 50%;
        height: auto;
    }
    
    .bjs{
        width: 100%;
        object-fit: contain;
        height: 269px;
    }

    .bjs img{
        width: 50%;
        height: auto;
    }
    
    .micron{
        width: 100%;
        object-fit: contain;
        height: 269px;
    }

    .micron img{
        width: 50%;
        height: auto;
    }
    
}


@media (max-width : 425px) {


    .bst img{
        width: 60%;
    }
    
    

    .rur img{
        width: 60%;
    }
    
    

    .earth img{
        width: 60%;
    }    

    .uscholar img{
        width: 60%;
    }
    

    .bjs img{
        width: 60%;
    }

    .micron img{
        width: 60%;
    }


    .bst{
        height: 150px;   
    }
    .rur{
        height: 100px;
    }
    .earth{
        height: 100px;
    }
    .uscholar{
        height: 150px;
    }
    .bjs{
        height: 150px;
    }
    .micron{
        height: 150px;
    }
    
}


/* @media only screen and (max-width: 600px) {
    
    .mtop {
        position: absolute;
        margin-top: -152px;
        margin-left: 247px;
    }
    .padt {
        padding-top: 215px;
        padding-left: 176px;
    }
    .st2 {
        font-size: 27px;
        text-align: left;
    }
.st3 {
    font-size: 27px;
    text-align: left;
    color: #7F7F7F;
}
.box-shadow-3d bst{
    width: 26em;
    margin-left: 306px;
}       .bst {
    width: 358px;
    height: 98px;
    margin-left: 367px;
}       .rur {
    width: 149px;
    height: 86px;
    margin-left: 367px;
}        .earth {
    width: 209px;
    height: 86px;
}      .uscholar {
    width: 358px;
    height: 86px;
    margin-left: 367px;
}      .bjs {
    width: 188px;
    height: 86px;
    margin-left: 367px;
}     .micron {
    width: 170px;
    height: 86px;
}   .bst img {
    width: 278px;
    height: 49px;
}    .rur img {
    width: 113px;
    height: 39px;
}.earth img {
    width: 105px;
    height: 57px;
}.uscholar img {
    width: 196px;
    height: 52px;
}.bjs img {
    width: 112px;
    height: 57px;
}.micron img {
    width: 103px;
    height: 73px;
}
.sizeadust{
    width: 107px;
}

} */