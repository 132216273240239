.container2{
    width: 85vw;
    margin: auto;
}

footer{
    padding: 52px 69px 42px 49px;
    background-color: #252525;
    width: 100vw;
    color: #fff;
}

h2 a{
    font-weight: 600;
    border: 5px solid #2FD549;
    background-color: transparent;
    padding: 0 25px;
    color: #fff;
    text-decoration: none;
    position: relative;
    z-index: 1;
}

h2 a::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #2FD549;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    z-index: -2;
    transition: all 0.3s linear;
}

h2 a:hover:after{    
    width: 100%;
    border-radius: 0rem;
}

footer section{
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer section h5{
    font-size: 18px;
}

footer section ul{
    display: flex;
    list-style: none;
}
    
footer section ul li a{
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    border-right: 3px solid #fff;
    padding-right: 10px;
    padding-left: 10px;
}

footer section ul li:last-child a{
    border: none;
}

.social-media img{
    width: 41px;
    height: 41px;
}




.mobile-footer{
    display: none;
    margin-top: 50px;
    justify-content: space-between;
    align-items: flex-end;
}

.mobile-footer ul{
    flex-direction: column;
    gap: 10px;
}

footer .mobile-footer ul li a{
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    border-right: none;
    padding-right: 0;
    padding-left: 0;
}

footer .mobile-footer .col-2{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    gap: 10px;
}


.mobile-contact-btn{
    display: none;
}

.copyright{
    font-size: 16px;
}

.copyright.mobile{
    display: none;
}


@media (max-width : 1380px) {
    .mobile-contact-btn{
        display: block;
    }

    .desktop-contact-btn{
        display: none;
    }
}


@media (max-width : 990px) {

    .mobile-footer{
        display: flex;
    }

    .desktop-footer{
        display: none;
    }
    

    .copyright.mobile{
        display: block;
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        
    }
   
    footer section ul li a{
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        border-right: 3px solid #fff;
        padding-right: 8px;
        padding-left: 8px;
    }

    footer section h5{
        font-size: 16px;
    }

    .social-media img{
        width: 35px;
        height: 35px;
    }

    .mobile-contact-btn{
        display: block;
    }

    .desktop-contact-btn{
        display: none;
    }

  }

  @media (max-width : 768px) {
    h2 a{
        padding: 0 15px;
        color: #fff;
        line-height: 71px;
    }

    footer{
        padding-right: 0;
        padding-left: 0;
    }

    
    
  }

  @media (max-width : 600px){

    footer{
        padding-right: 0;
        padding-left: 0;
    }
    footer .mobile-footer ul li a{
        font-size: 18px;
    }

    .mobile-footer nav {
        width: 50%;
    }

    footer .mobile-footer .col-2{
        width: 50%;
    }

  }


  @media (max-width : 990px) {

    footer section h5{
        font-size: 12px;
    }

  }

