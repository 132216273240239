@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* :root{
    
} */

h1{
    font-size: 64px;
    font-weight: 700;
}

h2{
    font-size: 40px;
    font-weight: 600;
}

h3{
    font-size: 24px;
    font-weight: 500;
}

h4{
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
}

p{
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
}

p.smaller{
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
}

span{
    color: #0031FF;
}


@media (max-width : 768px) {
    h1{
        font-size: 40px;
        font-weight: 700;
    }

    h2{
        font-size: 24px;
        font-weight: 600;
    }
    
    h3{
        font-size: 18px;
        font-weight: 500;
    }

    h4{
        font-size: 16px;
        font-weight: 600;
    }
    
    p{
        font-size: 16px;
        font-weight: 500;
    }

    p.smaller{
        font-size: 16px;
        font-weight: 400;
        line-height: 21.94px;
    }

}

@media (max-width : 400px) {
    h1{
        font-size: 36px;
        font-weight: 700;
    }
}

@media (max-width : 360px) {
    h1{
        font-size: 32px;
        font-weight: 700;
    }
}

@media (max-width : 360px) {
    h1{
        font-size: 28px;
        font-weight: 700;
    }
}



