.container{
    width: 85vw;
    margin: auto;
}
.piechart{
    margin: auto;
    display: flex;
    align-items: center;
}

.legend{
    display: flex;
    align-items: center;
    gap: 19px;
    margin-bottom: 16px;
}

.legend h3{
    width: 250px;
    font-size: 24px;
}

.box{
    height: 50px;
    width: 50px;
    background-color: #0031FF;
}

.box-1{
    background-color: #0031FF;
}
.box-2{
    background-color: #002AD8;
}
.box-3{
    background-color: #0022B0;
}
.box-4{
    background-color: #001B8C;
}

.box-5{
    background-color: #001466;
}

.box-6{
    background-color: #000C40;
}

.box-7{
    background-color: #00051B;
}

.box-8{
    background-color: #00030F;
}

.box-9{
    background-color: #000000;
}

.mobile-legends{
    display: none;
}


@media (max-width : 990px) {


    .desktop-legends{
        display: none;
    }

    .piechart{
        flex-direction: column;
    }

    .mobile-legends{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

}

@media (max-width : 990px) {


    .desktop-legends{
        display: none;
    }

    .piechart{
        flex-direction: column;
    }

    .mobile-legends{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

}

@media (max-width : 768px) {


    .legend h3{
        width: 200px;
        font-size: 22px;
    }

}

@media (max-width : 425px) {


    .legend h3{
        width: 200px;
        font-size: 18px;
    }

}