section.container{
    width: 85vw;
    margin: auto;
}

/* X- Factor Section Start */

section.x-factor{
    margin-top: 109px;

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.row{
    display: flex;
    justify-content: space-between;
}

section.x-factor .card{
    width: 31.45vw;
    padding-right: 106px;
    background-color: #F7F6F6;
    border-radius: 10px;
    padding-bottom: 40px;
    padding-left: 17px;
    color: #000;
    cursor: pointer;
    transition: scale 0.5s linear, opacity 0.5s linear;
}


section.x-factor .card{
    scale: 0;
    opacity: 1;
}

section.x-factor .card.active{
    scale: 1;
    opacity: 1;
}

section.x-factor .card h2{
    margin-bottom: 18px;
}

section.x-factor .card:hover{
    background-color: #0031FF;
    color: #fff;
}

section.x-factor .card:hover span{
    color: #000;
}




section.x-factor .row-2 .card{
    padding: 6px 0 6px;
    width: 73.125vw;
    margin: auto;
    display: flex;
    justify-content: center;
    position: relative;
}

.x-factor-image{
    height: 330px;
    width: 337px;
}

section.x-factor .row-2 .card-inner{
    width: 322px;
    position: absolute;
    left: 14px;
}

/* X- Factor Section Ends */


/* Your Growth Section Starts */


.your-growth{
    margin-top: 181px;
}

.your-growth h2{
    font-size: 187.53px;
}


/* Your Growth Section Ends */



/* Your Progress Section Starts */

.your-progress{
    color: #fff;
    background-color: #18232A;
    margin-top: 195px;
    margin-bottom: 115px;
    padding: 51px;
    padding-top: 0;
}

.your-progress h2{
    width: 636px;
    padding-top: 51px;
}

.star-card{
    width: 279px;
}


/* Pendulum Starts */


.pendulum-container{
    width: 70%;
    display: flex;
    gap: 132px;
    justify-content: center;
}

.pendulum{
    height: 463.13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
}

.pendulum .string{
    height: 100%;
    width: 1.5px;
    background-color: #fff;
    transform-origin: top center;
    
}

.pendulum .ball{
    height: 133px;
    width: 133px;
    border-radius: 100%;
    background-color: #0031FF;
    position: absolute;
    bottom: 0;
}

.pendulum-container.animate .pendulum .string.first{
    animation: pendulumString 1s forwards linear;
}

/* .pendulum .string.first{
    animation: pendulumString 1s forwards linear;
} */

.pendulum-container.animate .pendulum .ball.first{
    animation: pendulumBall 1s forwards linear;  
}

.pendulum .ball.second{
    background-color: #0029D5;
}

.pendulum .ball.third{
    background-color: #001FA4;
}


.pendulum-container.animate .pendulum .string.last{
    animation: pendulumStringLast 1s alternate linear;
    animation-delay: 1s;
}

.pendulum-container .pendulum .ball.last{
    background-color: #011777;
}

.pendulum-container.animate .pendulum .ball.last{
    background-color: #011777;
    animation: pendulumBallLast 1s alternate linear;
    animation-delay: 1s;
}



@keyframes pendulumString {
    0% {
        transform: rotateZ(20deg);
    }
        
    100%{
        transform: rotateZ(0);
    }
}

@keyframes pendulumBall {
    0% {
        transform: translateX(-150px);
    }
        
    100%{
        transform: translateX(0);
    }
}

@keyframes pendulumStringLast {
    
    50%{
        transform: rotateZ(-20deg);
    }
    100%{
        transform: rotateZ(0);
    }
}

@keyframes pendulumBallLast {
    

    50%{
        transform: translateX(150px);
    }
    
    100%{
        transform: translateX(0);

    }
}


/* Pendulum Ends */



.your-progress .row-2{
    justify-content: flex-start;
    gap: 35px;
    margin-top: 114px;
    margin-left: 44px;
}

/* Your Progress Section Ends */


/* Second Container Starts */

/* .container-2{
    width: 95vw;
    margin: 115px auto 0;
} */


/* Accordian Starts */

.accordian{
    margin-top: 87px;
    border-bottom: 3px solid #1C1C1C;
    padding: 0 56px 42px 26px;
    cursor: pointer;
}

.accordian .accordian-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordian .accordian-header h2{
    width: 509px;
}

.accordian .accordian-header p{
    width: 388px;
}

.accordian .accordian-header img{
    transform: rotateZ(0deg);
    transition: all 0.5s ease;
}

.accordian .accordian-header img.active{
    transform: rotateZ(180deg);
    transition: all 0.5s ease;
}

.accordian .accordian-body{
    margin-top: 46px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;
}

.accordian .accordian-body.active{
    max-height: 200px;
}

.accordian .accordian-body p{
    width: 974px;
}




/* Accordian Ends */


/* Services Section Starts */


.service-container{
    display: flex;
    justify-content: space-between;
}

.service-container a{
    text-decoration: none;
    color: #000;
}

.service-card{
    border: 1px solid #000000;
    position: relative;
}

.service-card{
    border: 1px solid #000000;
    width: 382px;
    height: 494px;
    cursor: pointer;
}

.service-card .image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
}

.service-card:hover .image-container{
    background-color: #0031FF;
}

.service-card .info{
    padding: 0 15px;
}

.service-card p{
    color: #1209E7;
    margin-top: 28px;
}

.service-card p span{
    color: #000;
}

.service-card .arrow-image-container{
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
}

.service-card:hover .arrow-image-container{
    background-color: #2FD549;

}


/* Services Section Ends */


/* FAQ Section Starts */
.faqs{
    display: flex;
    margin-top: 188px;
    justify-content: space-between;
    margin-bottom: 50px;
}

.mobile-faq-header{
    display: none;
}

.faq-container{
    width: 773px;
}

.faq-card{
    padding: 44px;
    color: #000;
    cursor: pointer;
    border-bottom: 1px solid #BEBEBE;
    transform: translateX(-50px);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease ;
    position: relative;
    /* transition: all linear 0.5s; */
}

.faq-card.active{
    transform: translateX(0);
    opacity: 1;
}

.faq-card::before{
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)  ;
    bottom: 0;
    height: 100%;
    width: 0%;
    position: absolute;
    background-color: #0029D5;
    z-index: -1;
    transition: width linear 0.3s;
}

.faq-card:hover:before{
    width: 100%;
    border-radius: 6px;
    border-bottom: 1px solid #0031FF;
}

.faq-card:hover{
    color: #fff;
    border-color: transparent;
}

.faq-card:hover img{
    filter: invert();
    transition: all linear 0.6s;
}


.faq-card .faq-header{
    display: flex;
    align-items: center;
    gap: 30px;
}

.faq-card .faq-body{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
}

.faq-card .faq-body.active{
    margin-top: 24px;
    max-height: 300px;
}



@media (max-width : 990px) {
    section.x-factor .card{
        width: 45%;
        padding-right: 50px;
    }

    section.x-factor .row-2 .card{
        padding: 6px 0 6px;
        width: 90%;
    }

    section.x-factor .row-2 .card-inner{
        width: 45%;
    }

    .x-factor-image{
        height: 250px;
        width: 255px;
    }

    .your-growth h2{
        font-size: 150px;
    }

    .your-growth .row .col-1{
        width: 45%;
    }

    .your-growth .row .col-2{
        width: 45%;
    }

    .your-progress{
        margin-top: 50px;
    }

    .your-progress .row{
        flex-direction: column;
        justify-content: center;
    }

    .your-progress h2{
        width: 100%;
        padding-bottom: 51px;
        text-align: center;
    }

    .pendulum-container{
        width: 100%;
        border-top: 2px solid #fff;
        justify-content: center;
    }

    .your-progress .row-2{
        flex-direction: row;
        gap: 3%;
        row-gap: 50px;
        margin-top: 70px;
        margin-left: 0;
        flex-wrap: wrap;
    }

    .star-card{
        width: 45%;
    }

    .service-card{
        width: 32%;
        height: 494px;
    }

    .faqs{
        margin-top: 90px;
        flex-direction: column;
        align-items: center;
    }
    
    .desktop-faq-header{
        display: none;
    }

    .mobile-faq-header{
        display: block;
        text-align: center;
        margin-bottom: 50px;
    }

    .faq-container{
        width: 100%;
    }
}


@media (max-width : 768px) {


    section.x-factor {
        margin-top: 50px;
    }

    section.x-factor .row{
        flex-wrap: wrap;
        gap: 5px;
    }
    section.x-factor .card{
        width: 100%;
        height: 330px;
        padding-right: 50px;
        padding-top: 50px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    section.x-factor .row-2 .card{
        width: 100%;
    }

    section.x-factor .card h2{
        font-size: 54px;
    }

    section.x-factor .row-2 .card-inner{
        width: 100%;
        position: static;
        padding-right: 50px;
        padding-left: 17px;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .x-factor-image{
        display: none;
    }

    .factor{
        display: none;
    }

    .your-growth{
        margin-top: 75px;
    }

    .your-growth h2{
        font-size: 150px;
    }

    .your-growth .row {
        flex-wrap: wrap;
        gap: 50px;
    }

    .your-growth .row .col-1{
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .your-growth .row .col-2{
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .your-progress{
        padding: 0;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }


    .pendulum-container{
        gap: 110px;
    }

    .pendulum{
        height: 380px;
    }

    .pendulum .ball{
        height: 110px;
        width: 110px;
    }

    .accordian{
        padding: 0;
        cursor: pointer;
    }

    .accordian .accordian-header h2{
        width: 45%;
    }
    
    .accordian .accordian-header p{
        width: 40%;
    }

    .accordian .accordian-header img{
        width: 8%;
    }

    .accordian .accordian-body{
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .accordian .accordian-body p{
        width: 100%;
    }

    .service-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .service-card{
        width: 75%;
    }
}


@media (max-width : 600px){
    .pendulum-container{
        gap: 80px;
    }

    .pendulum{
        height: 250px;
    }

    .pendulum .ball{
        height: 80px;
        width: 80px;
    }

    h4{
        font-size: 16px;
    }

    .your-progress .row-2{
        flex-direction: column;
        gap: 30px;
    }

    .star-card{
        width: 100%;
    }

    .accordian .accordian-header h2{
        width: 45%;
    }
    
    .accordian .accordian-header p{
        width: 45%;
    }

    .accordian .accordian-header img{
        width: 8%;
    }

    .faq-card{
        padding: 22px;
    }
}

@media (max-width : 425px){

    .accordian .accordian-header h2{
        font-size: 24px;
        width: 45%;
    }
    
    .accordian .accordian-header p{
        width: 48%;
        font-size: 14px;
        line-height: 18px;

    }

    .accordian .accordian-body p{
        font-size: 14px;

    }
    .accordian .accordian-header img{
        width: 8%;
    }

    .service-card{
        width: 90%;
        height: 330px;
    }

    .service-card .image-container{
        height: 180px;
    }

    .arrow-image-container{
        height: 50px;
        width: 50px; 
    }

    .arrow-image-container img{
        height: 35px;
        width: 35px; 
    }


    @keyframes pendulumString {
        0% {
            transform: rotateZ(10deg);
        }
            
        100%{
            transform: rotateZ(0);
        }
    }
    
    @keyframes pendulumBall {
        0% {
            transform: translateX(-40px);
        }
            
        100%{
            transform: translateX(0);
        }
    }
    
    @keyframes pendulumStringLast {
        
        50%{
            transform: rotateZ(-10deg);
        }
        100%{
            transform: rotateZ(0);
        }
    }
    
    @keyframes pendulumBallLast {
        
    
        50%{
            transform: translateX(40px);
        }
        
        100%{
            transform: translateX(0);
    
        }
    }


}


@media (max-width : 375px) {


    section.x-factor .card{
        padding-right: 17px;

    }

    section.x-factor .row-2 .card{
        width: 100%;
    }

    section.x-factor .row-2 .card-inner{
        padding-right: 17px;
        padding-left: 17px;
    }

    .your-growth h2{
        font-size: 100px;
    }


    .accordian .accordian-header h2{
        font-size: 22px;
        width: 45%;
    }
    
    .accordian .accordian-header p{
        width: 48%;
        font-size: 12px;
        line-height: 18px;
    }

    .accordian .accordian-header img{
        width: 8%;
    }
    
}


@media (max-width : 320px) {
    section.x-factor .card h2{
        font-size: 36px;
    }


    .pendulum-container{
        gap: 70px;
    }

    .pendulum{
        height: 250px;
    }

    .pendulum .ball{
        height: 70px;
        width: 70px;
    }


}



/* 
@media only screen and (max-width: 600px) {

    .col-2{
        margin-left: -494px;   
    }.your-progress .row-2 {
        justify-content: flex-start;
        gap: 35px;
        margin-top: 114px;
        margin-left: -369px;
    }.faq-container {
        width: 773px;
        margin-left: -377px;
    }
    .blast{
        margin-left: -130px;
    }
} */





/* FAQ Section Ends */


/* Second Container Ends */





