/* Detail Info Starts */


.container-3.dark{
    background-color: #F7F6F6;
}

.container-3.light{
    background-color: #fff;
}

.graph-info{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 86px 0 112px;
    flex-direction: column;
    gap: 21px;
}

.mql{
    width: 100%;
}

.mql h2{
    margin-bottom: 20px;
}

.mql p{
    margin-bottom: 25px;
}

.service-accordian-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1%;

}

.accordian-column{
    width: 32%;
}

.accordian-info{
    width: 100%;
    cursor: pointer;
    padding: 21px 35px;
    margin-bottom: 24px;
    min-height: 120px;
}

.accordian-column-0 .accordian-info.dark.gradient{
    background: url('../assets/images/first-accordian-blue.png');
}

.accordian-column-1 .accordian-info.dark.gradient{
    background: url('../assets/images/second-accordian-blue.png');
}

.accordian-column-2 .accordian-info.dark.gradient{
    background: url('../assets/images/third-accordian-blue.png');
}

.accordian-column-0 .accordian-info.light.gradient{
    background: url('../assets/images/first-accordian-gray.png');
    color: #fff;

}

.accordian-column-1 .accordian-info.light.gradient{
    background: url('../assets/images/second-accordian-gray.png');
    color: #fff;

}

.accordian-column-2 .accordian-info.light.gradient{
    background: url('../assets/images/third-accordian-gray.png');
    color: #fff;
}

.accordian-info.dark{
    background-color: #454545;
    color: #fff;
}

.accordian-info.light{
    background-color: #F4F4F4;
    color: #000;
}
    
.accordian-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.accordian-header img{
    transform: rotateZ(0deg);
    transition: all 0.5s linear;
}

.accordian-header img.active{
    transform: rotateZ(180deg);
}

.accordian-body{
    max-height: 0;
    overflow: hidden;
    margin-top: 16px;
    transition: all 0.5s linear;
}

.accordian-body.active{
    max-height: 1800px; 
}

/* Detail Info Ends */



@media (max-width: 990px){
    .graph-info{
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        padding: 86px 0 112px;
    }

    .mql{
        width: 100%;
    }

    .mql p{
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .accordian-info{
        width: 100%;
    }

    .accordian-column{
        width: 49%;
    }

}

@media (max-width: 600px){
    .accordian-column{
        width: 100%;
    }

    .accordian-info{
        min-height: auto;
    }

}

@media (max-width: 430px){
    .accordian-info{
        padding: 12px 16px;
    }

    .accordian-header img{
        width: 30px;
    }


    .graph-info{
        padding: 50px 0 52px;
    }

}
